import { FC, useEffect, useState, ChangeEvent } from "react";
import { CheckIcon, Squares2X2Icon } from "@heroicons/react/24/outline";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import { Checkbox } from "@headlessui/react";
import toast from "react-hot-toast";
import { formatDateTime, useData } from "data/data-provider";
import { getInvoice } from "api/Invoice/invoice";
import { Booking } from "api/booking/booking";
import { globalJson } from "global/global_json";
import RentalCarDatesRangeInput from "components/HeroSearchForm/(car-search-form)/RentalCarDatesRangeInput";
import Input from "shared/Input/Input";
import { getBonzahProducts } from "api/bonzah/bonzah";
import { AddCustomer } from "api/customer/customer";
import moment from "moment";
import Badge from "shared/Badge/Badge";

const ListingCarDetail: FC<{}> = () => {
  const thisPathname = useLocation().pathname;
  const navigate = useNavigate();

  const {
    selectedCar,
    user,
    startDate,
    startHours,
    startMinutes,
    startPeriod,
    endDate,
    endHours,
    endMinutes,
    pickupLocation,
    setInvoice,
    selectedExtras,
    setSelectedExtras,
    bonzahProducts,
    setBookingdetails,
    setUser,
    setBonzahProducts,
    setSelectedBonzahProducts,
    selectedBonzahProducts,
    invoice,
    dropOffLocation,
    searchedPickupLoction,
    searchedDropoffLocation,
    sameDropoffLocation,
    dropoffLocationType,
    endPeriod,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    phone,
    setPhone,
  } = useData();

  let [bonzahProductsLoading, setBonzahProductsLoading] = useState(true);

  const [selectedBonzahType, setSelectedBonzahType] = useState<any>(null);

  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [isMoreThan30Days, setIsMoreThan30Days] = useState(false);

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!firstName) newErrors.firstName = "First Name is required";
    if (!lastName) newErrors.lastName = "Last Name is required";
    if (!email || !/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
      newErrors.email = "Please enter a valid email address";
    }
    if (!phone || phone.length < 10) {
      newErrors.phone = "Please enter a valid phone number";
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return false if there are any errors
  };

  // Clear specific field error
  const clearError = (field: string) => {
    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      delete updatedErrors[field];
      return updatedErrors;
    });
  };

  const fetchBonzahProducts = async (selectedType?: string) => {
    setBonzahProductsLoading(true);

    const payload = {
      company_id: globalJson.company_id,
      selected_type: selectedType,
    };

    const { data, error } = await getBonzahProducts(payload);

    if (data) {
      setBonzahProducts(data);
      setBonzahProductsLoading(false);
    } else if (error) {
      setBonzahProductsLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      const start = moment(startDate);
      const end = moment(endDate);
      const daysDifference = end.diff(start, "days");

      if (daysDifference > 30) {
        setIsMoreThan30Days(true);
        toast("Bonzah Insurance is not available for more than 30 days", {
          style: {
            background: "#fff3cd",
            color: "#856404",
          },
        });
      } else {
        setIsMoreThan30Days(false);
      }
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const fetchBonzahProducts = async (selectedType?: string) => {
      setBonzahProductsLoading(true);

      const payload = {
        company_id: globalJson.company_id,
        selected_type: selectedType,
      };

      const { data, error } = await getBonzahProducts(payload);

      if (data) {
        setBonzahProducts(data);
        const standardProtection = data.insurance_options.find(
          (option: any) => option.title.toLowerCase() === "standard protection"
        );

        if (standardProtection) {
          return;
        } else {
          setSelectedBonzahType(null);
          setSelectedBonzahProducts(new Set()); // No insurance available
        }
        setBonzahProductsLoading(false);
      } else if (error) {
        setBonzahProductsLoading(false);
      }
    };
    fetchBonzahProducts();
  }, []);

  const toggleAddonSelection = (addonType: string) => {
    setSelectedBonzahProducts((prevSelected) => {
      const updatedSet = new Set(prevSelected);
      if (updatedSet.has(addonType)) {
        updatedSet.delete(addonType);
      } else {
        updatedSet.add(addonType);
      }
      return updatedSet;
    });
  };

  useEffect(() => {
    const fetchInvoice = async () => {
      if (!startDate || !endDate) {
        toast.error("No start date or end date");
        return;
      }
      const selectedInsuranceOptions = Array.from(
        selectedBonzahProducts
      )?.filter((item) =>
        bonzahProducts.insurance_options?.some(
          (option: any) =>
            option.title.toLowerCase() === item && item !== "standard"
        )
      );

      const selectedAddons = Array.from(selectedBonzahProducts).filter((item) =>
        bonzahProducts?.addons?.some((addon: any) => addon.value[0] === item)
      );

      const insuranceOptionsValues = selectedInsuranceOptions
        .map((item) => {
          const insuranceOption = bonzahProducts.insurance_options.find(
            (option: any) => option.title.toLowerCase() === item
          );
          return insuranceOption ? insuranceOption.value : [];
        })
        .flat(); // Flatten the array to get all values in a single array

      const addonsValues = selectedAddons
        .map((item) => {
          const addon = bonzahProducts.addons.find(
            (addon: any) => addon.value[0] === item
          );
          return addon ? addon.value : [];
        })
        .flat();

      const payload = {
        customer: user?.id,
        fleet: selectedCar?.id,
        pick_up_time: formatDateTime(
          startDate,
          startHours,
          startMinutes,
          startPeriod
        ),
        drop_off_time: formatDateTime(endDate, endHours, endMinutes, endPeriod),
        pick_up_location: pickupLocation?.id,
        drop_off_location:
          sameDropoffLocation === false
            ? dropOffLocation?.id
            : pickupLocation?.id,
        custom_pick_up_address: searchedPickupLoction,
        custom_drop_off_address:
          sameDropoffLocation === false
            ? searchedDropoffLocation
            : searchedPickupLoction,
        same_drop_off_address: sameDropoffLocation,
        extras: Array.from(selectedExtras),
        bonzah_insurance_options: [...insuranceOptionsValues, ...addonsValues],
      };

      const data = await getInvoice(payload);
      setInvoice(data);
    };
    if (!bonzahProductsLoading) {
      fetchInvoice();
    }
  }, [selectedExtras, selectedBonzahProducts, bonzahProductsLoading]);

  useEffect(() => {
    setSelectedExtras(new Set());
    setSelectedBonzahProducts(new Set());
    handleBonzahProductSelection("standard protection");
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("user");
  }, []);

  const handleBooking = async () => {
    if (!validateForm()) {
      toast.error("Please fill in the details");
      return;
    }
    if (selectedBonzahProducts.size === 0) {
      toast.error("Please select an insurance option before proceeding.");
      return;
    }

    setLoading(true);

    const selectedInsuranceOptions = Array.from(selectedBonzahProducts).filter(
      (item) =>
        bonzahProducts?.insurance_options?.some(
          (option: any) =>
            option.title.toLowerCase() === item && item !== "standard"
        )
    );

    const selectedAddons = Array.from(selectedBonzahProducts).filter((item) =>
      bonzahProducts?.addons?.some((addon: any) => addon.value[0] === item)
    );

    const insuranceOptionsValues: string[] = selectedInsuranceOptions
      .map((item) => {
        const insuranceOption = bonzahProducts.insurance_options.find(
          (option: any) => option.title.toLowerCase() === item
        );
        return insuranceOption ? insuranceOption.value : [];
      })
      .flat();

    const addonsValues: string[] = selectedAddons
      .map((item) => {
        const addon = bonzahProducts.addons.find(
          (addon: any) => addon.value[0] === item
        );
        return addon ? addon.value : [];
      })
      .flat();

    const customerPayload = {
      first_name: firstName,
      last_name: lastName,
      email,
      phone_no: phone,
      company: globalJson.company_id,
    };

    try {
      const response = await AddCustomer(customerPayload);
      if (response.error) {
        toast.error(response.error);
        return;
      }
      if (!startDate || !endDate) {
        toast.error("No start date or end date");
        return;
      }

      if (response.data) {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("refresh_token", response.data.refresh_token);
        localStorage.setItem("user", JSON.stringify(response.data));

        const customerId = response.data.id;

        const payload = {
          customer: customerId,
          fleet: selectedCar?.id,
          pick_up_time: formatDateTime(
            startDate,
            startHours,
            startMinutes,
            startPeriod
          ),
          drop_off_time: formatDateTime(
            endDate,
            endHours,
            endMinutes,
            endPeriod
          ),
          pick_up_location: pickupLocation?.id,
          drop_off_location:
            sameDropoffLocation === false
              ? dropOffLocation?.id
              : pickupLocation?.id,
          custom_pick_up_address: searchedPickupLoction,
          custom_drop_off_address:
            sameDropoffLocation === false
              ? searchedDropoffLocation
              : searchedPickupLoction,
          same_drop_off: sameDropoffLocation,
          extras: Array.from(selectedExtras),
          bonzah_insurance_options: [
            ...insuranceOptionsValues,
            ...addonsValues,
          ],
        };

        const bookingResponse = await Booking(payload);

        if (bookingResponse?.error) {
          toast.error(bookingResponse.error);
          setLoading(false);
          return;
        } else {
          setBookingdetails(bookingResponse);
          navigate("/checkout");
        }
      }
    } catch (err: any) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleBonzahProductSelection = (productType: any) => {
    setSelectedBonzahType(productType);
    setSelectedBonzahProducts(new Set([productType]));

    fetchBonzahProducts(productType);
  };

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!pickupLocation) {
      navigate("/");
    }
  }, [pickupLocation]);

  const handleOpenModalImageGallery = () => {
    navigate(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderUserSection = () => {
    return (
      <div className="border-neutral-700 flex flex-col sm:rounded-2xl border-b sm:border-t sm:border-l sm:border-r sm:space-y-8 pb-10 px-0 sm:p-4 xl:p-8 !space-y-6">
        {" "}
        <h2 className="text-2xl sm:text-3xl text-[#111827] dark:text-white lg:text-3xl font-bold">
          Renter Information
        </h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <div className="border p-2 rounded-xl">
              <span className="my-2 text-sm">First Name</span>
              <Input
                placeholder="John"
                value={firstName}
                className="border-none !p-0 focus:outline-none focus:ring-0"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFirstName(e.target.value);
                  if (e.target.value) clearError("firstName"); // Clear error if input is valid
                }}
              />
            </div>
            {errors.firstName && (
              <span className="text-red-500 text-xs font-semibold">
                {errors.firstName}
              </span>
            )}
          </div>
          <div>
            <div className="border p-2 rounded-xl">
              <span className="my-2 text-sm">Last Name</span>
              <Input
                placeholder="Doe"
                value={lastName}
                className="border-none !p-0 focus:outline-none focus:ring-0"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLastName(e.target.value);
                  if (e.target.value) clearError("lastName");
                }}
              />
            </div>
            {errors.lastName && (
              <span className="text-red-500 text-xs font-semibold">
                {errors.lastName}
              </span>
            )}
          </div>
          <div>
            <div className="border p-2 rounded-xl">
              <span className="my-2 text-sm">Email</span>
              <Input
                placeholder="Johndoe@gmail.com"
                type="email"
                className="border-none !p-0 focus:outline-none focus:ring-0"
                value={email}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value);
                  if (
                    /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(
                      e.target.value
                    )
                  ) {
                    clearError("email");
                  }
                }}
              />
            </div>
            {errors.email && (
              <span className="text-red-500 text-xs font-semibold">
                {errors.email}
              </span>
            )}
          </div>
          <div>
            <div className="border p-2 rounded-xl">
              <span className="my-2 text-sm">Phone</span>
              <PhoneInput
                country={"us"}
                value={phone}
                onChange={(phone) => {
                  setPhone(phone);
                  if (phone.length >= 10) clearError("phone");
                }}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: false,
                }}
                inputStyle={{
                  border: "none", // Darker border for dark theme
                  backgroundColor: "transparent", // Dark background
                  color: "#fff", // Light text for readability
                  borderRadius: "0.5rem",
                  width: "100%",
                  minHeight: "44px",
                  boxShadow: "none",
                }}
                buttonStyle={{
                  backgroundColor: "transparent", // Matches input background
                  border: "none", // Darker border for consistency
                  borderRadius: "0.5rem 0 0 0.5rem", // Rounded on the left side only
                  color: "#fff", // Light text for readability
                }}
                dropdownStyle={{
                  // backgroundColor: "#140B0B",
                  // border: "1px solid #444",
                  color: "black",
                  // borderRadius: "0.75rem",
                  // maxHeight: "200px",
                  // overflowY: "auto",
                }}
              />
            </div>
            {errors.phone && (
              <span className="text-red-500 text-xs font-semibold ">
                {errors.phone}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  };

  const handleExtraChange = (extraId: any, checked: any) => {
    setSelectedExtras((prevSelectedExtras) => {
      const updatedExtras = new Set(prevSelectedExtras);
      if (checked) {
        updatedExtras.add(extraId);
      } else {
        updatedExtras.delete(extraId);
      }
      return updatedExtras;
    });
  };

  const renderExtrasSection = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-bold text-[#111827] dark:text-white">
          Extras
        </h2>
        {!bonzahProductsLoading &&
          !isMoreThan30Days &&
          bonzahProducts?.addons?.map((addon: any) => (
            <div
              key={addon.title}
              className="flex flex-col justify-between items-start border border-neutral-700 rounded-md p-4 bg-white dark:bg-transparent max-w-full"
            >
              <div className="flex items-start">
                <Checkbox
                  checked={selectedBonzahProducts.has(addon.value[0])}
                  onChange={() => toggleAddonSelection(addon.value[0])}
                  className="group size-7 rounded-md p-1 ring-1 ring-primary-200 data-[checked]:bg-primary-200 data-[checked]:bg-opacity-20 ring-inset  mr-4"
                >
                  <CheckIcon
                    className={`size-5 stroke-primary-200 ${
                      selectedBonzahProducts.has(addon.value[0])
                        ? "visible"
                        : "invisible"
                    }`}
                  />
                </Checkbox>

                <div>
                  <div className="flex items-center mb-2">
                    <h2 className="text-lg md:text-xl font-bold text-gray-900 dark:text-white">
                      {addon.title}
                    </h2>
                    <button
                      className="ml-2 text-gray-500 dark:text-gray-400"
                      onClick={() => window.open(addon.pdf, "_blank")}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill="#1B2430"
                          d="M9.997 18.958c-4.941 0-8.958-4.016-8.958-8.958s4.017-8.958 8.958-8.958c4.942 0 8.959 4.016 8.959 8.958s-4.017 8.958-8.959 8.958Zm0-16.666C5.747 2.292 2.29 5.75 2.29 10s3.458 7.708 7.708 7.708S17.706 14.25 17.706 10s-3.459-7.708-7.709-7.708Z"
                        />
                        <path
                          fill="#1B2430"
                          d="M10 11.458a.63.63 0 0 1-.625-.625V6.667A.63.63 0 0 1 10 6.042a.63.63 0 0 1 .625.625v4.166a.63.63 0 0 1-.625.625Zm-.003 2.709a.829.829 0 0 1-.316-.067.963.963 0 0 1-.275-.175.86.86 0 0 1-.175-.275.83.83 0 0 1-.067-.317.83.83 0 0 1 .067-.316c.041-.1.1-.192.175-.275a.96.96 0 0 1 .275-.175.833.833 0 0 1 .633 0c.1.041.192.1.275.175a.963.963 0 0 1 .175.275.83.83 0 0 1 .067.316.83.83 0 0 1-.067.317.86.86 0 0 1-.175.275.964.964 0 0 1-.275.175.83.83 0 0 1-.317.067Z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="mt-4 md:mt-0 w-full">
                <p className="lg:text-2xl text-lg font-bold text-gray-900 dark:text-gray-100 flex justify-end items-center">
                  ${addon.price.toFixed(2)}
                  <span className="text-gray-400 lg:text-lg">/day</span>
                </p>
              </div>
            </div>
          ))}
        {selectedCar?.extras.map((extra) => (
          <div
            key={extra.id}
            className="w-full rounded-md border border-neutral-700 flex justify-between items-center p-5"
          >
            <div className="flex justify-start">
              <Checkbox
                checked={selectedExtras?.has(extra.id)}
                onChange={(checked) => handleExtraChange(extra.id, checked)}
                className="group size-7 rounded-md  p-1 ring-1 ring-primary-200 ring-inset data-[checked]:bg-primary-200 data-[checked]:bg-opacity-20  mr-4"
              >
                <CheckIcon
                  className={`size-5 stroke-primary-200 ${
                    selectedExtras.has(extra.id) ? "visible" : "invisible"
                  }`}
                />
              </Checkbox>
              <div>
                <div className="text-gray-900 dark:text-gray-200 lg:text-lg sm:text-l font-bold flex space-x-2">
                  <span>{extra.name}</span>
                </div>
                <div className="text-gray-400 dark:text-neutral-400 lg:text-lg sm:text-md font-medium ">
                  {renderDescriptionWithLink(extra.description)}
                </div>
              </div>
            </div>

            <div className="mt-4 md:mt-0">
              <div className="flex justify-end">
                {extra.is_required === true && (
                  <Badge color="blue" name="Recommended" />
                )}
              </div>
              <p className="lg:text-2xl text-lg font-bold text-gray-900 dark:text-gray-100 flex justify-end items-center">
                ${extra.amount}
                <span className="text-gray-400 lg:text-lg">
                  {" "}
                  /{extra.nature === "per_day" ? "day" : "trip"}
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderDescriptionWithLink = (description: string) => {
    // Regular expression to detect URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    // Split the description into parts: text and URLs
    const parts = description.split(urlRegex);

    return parts.map((part, index) => {
      // If the part matches the URL pattern, render it as a clickable link
      if (urlRegex.test(part)) {
        return (
          <a
            key={index}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            {part}
          </a>
        );
      }
      // Otherwise, render the text normally
      return <span key={index}>{part}</span>;
    });
  };

  const renderBonzahSection = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl text-[#111827] dark:text-white font-bold">
          Trip Insurance
        </h2>

        {bonzahProducts?.insurance_options
          ?.filter(
            (product: any) =>
              !(isMoreThan30Days && product.insurance_by_bonzah === true)
          )
          .map((product: any) => (
            <div
              key={product.title}
              className="flex flex-col justify-between items-start border border-neutral-700 rounded-lg p-4 bg-white dark:bg-transparent max-w-full"
            >
              <div className="flex items-start">
                <Checkbox
                  checked={selectedBonzahType === product.title.toLowerCase()}
                  onChange={() =>
                    handleBonzahProductSelection(product.title.toLowerCase())
                  }
                  className="group size-7 rounded-md p-1 ring-1 ring-primary-200 ring-inset data-[checked]:bg-primary-200 data-[checked]:bg-opacity-20  mr-4"
                >
                  <CheckIcon
                    className={`size-5 stroke-primary-200 ${
                      selectedBonzahType === product.title.toLowerCase()
                        ? "visible"
                        : "invisible"
                    }`}
                  />
                </Checkbox>

                <div>
                  <div className="flex items-center mb-2">
                    <h2 className="text-lg md:text-xl font-bold text-gray-900 dark:text-gray-100">
                      {product.title}
                    </h2>
                    {product?.pdf !== "" && (
                      <button
                        className="ml-2 text-gray-500 dark:text-gray-400"
                        onClick={() => window.open(product.pdf, "_blank")}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="none"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill="#1B2430"
                            d="M9.997 18.958c-4.941 0-8.958-4.016-8.958-8.958s4.017-8.958 8.958-8.958c4.942 0 8.959 4.016 8.959 8.958s-4.017 8.958-8.959 8.958Zm0-16.666C5.747 2.292 2.29 5.75 2.29 10s3.458 7.708 7.708 7.708S17.706 14.25 17.706 10s-3.459-7.708-7.709-7.708Z"
                          />
                          <path
                            fill="#1B2430"
                            d="M10 11.458a.63.63 0 0 1-.625-.625V6.667A.63.63 0 0 1 10 6.042a.63.63 0 0 1 .625.625v4.166a.63.63 0 0 1-.625.625Zm-.003 2.709a.829.829 0 0 1-.316-.067.963.963 0 0 1-.275-.175.86.86 0 0 1-.175-.275.83.83 0 0 1-.067-.317.83.83 0 0 1 .067-.316c.041-.1.1-.192.175-.275a.96.96 0 0 1 .275-.175.833.833 0 0 1 .633 0c.1.041.192.1.275.175a.963.963 0 0 1 .175.275.83.83 0 0 1 .067.316.83.83 0 0 1-.067.317.86.86 0 0 1-.175.275.964.964 0 0 1-.275.175.83.83 0 0 1-.317.067Z"
                          />
                        </svg>
                      </button>
                    )}
                  </div>

                  {product?.options &&
                    Object.entries(product.options).map(([key, values]) => (
                      <div key={key} className="mt-2">
                        {!product.canopy && product.insurance_by_bonzah && (
                          <h3 className="font-semibold text-gray-700 dark:text-gray-300 uppercase">
                            {key}
                          </h3>
                        )}
                        <p className="text-sm text-gray-600 dark:text-gray-400">
                          {(Array.isArray(values) ? values : [values]).map(
                            (detail, index) => (
                              <span
                                className={`${
                                  product.canopy
                                    ? "text-red-500 font-semibold"
                                    : "text-gray-600"
                                } `}
                                key={index}
                              >
                                {detail}
                                <br />
                              </span>
                            )
                          )}
                        </p>
                      </div>
                    ))}
                </div>
              </div>

              <div className="mt-4 md:mt-0 w-full flex justify-end items-center">
                <p className="lg:text-2xl text-lg font-bold text-gray-900 dark:text-gray-100">
                  ${product.price.toFixed(2)}
                  <span className="text-gray-400 lg:text-lg">
                    /
                    {product.nature === "per_trip"
                      ? "trip"
                      : product.nature === "per_day"
                      ? "day"
                      : "24 hours"}
                  </span>
                </p>
              </div>
            </div>
          ))}
      </div>
    );
  };

  const renderSidebarPrice = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl p-6 lg:p-12 lg:max-w-xl  ">
        <h2 className=" text-2xl sm:text-3xl lg:text-3xl font-bold text-[#111827] dark:text-white">
          Booking Details
        </h2>
        <div className="mt-8 flex">
          <div className="flex-shrink-0 flex flex-col items-center py-2">
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
          </div>
          <div className="ml-4 space-y-14 text-sm">
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                Pick up
              </span>
              <span className=" font-semibold">
                {" "}
                {searchedPickupLoction
                  ? searchedPickupLoction
                  : pickupLocation?.name}
              </span>
              <span className=" font-semibold !mt-0">
                {moment(startDate).format("ddd, D MMM YYYY")}{" "}
                {moment(startDate).format("hh:mm A")}
              </span>
            </div>
            <div className="flex flex-col space-y-2">
              <span className=" text-neutral-500 dark:text-neutral-400">
                Drop Off
              </span>
              <span className=" font-semibold">
                {dropoffLocationType === false &&
                searchedDropoffLocation.length > 0
                  ? searchedDropoffLocation
                  : dropOffLocation?.name
                  ? dropOffLocation?.name
                  : dropoffLocationType === true &&
                    searchedPickupLoction.length > 0
                  ? searchedPickupLoction
                  : pickupLocation?.name}
              </span>
              <span className=" font-semibold !mt-0">
                {moment(endDate).format("ddd, D MMM YYYY")}{" "}
                {moment(endDate).format("hh:mm A")}
              </span>
            </div>
          </div>
        </div>

        {/* <form className=" rounded-none">
          <RentalCarDatesRangeInput isStatic={true} />
        </form> */}
        <div className="w-full  mx-auto bg-white dark:bg-transparent">
          <h2 className="text-xl font-medium text-gray-900 dark:text-gray-100">
            Rental Bill
          </h2>
          <p className="mt-2 text-sm text-gray-500 dark:text-gray-400">
            Prices may change depending on the length of the rental and the
            price of your rental car.
          </p>
          <div className="mt-4">
            <div className="pb-4">
              <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                Trip Price
              </span>
            </div>
            <div className="flex justify-between ">
              <span>{selectedCar?.name}</span>
              <span className="flex flex-col items-end ">
                <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice?.car_total}
                </span>
                <span className="text-sm text-gray-500 dark:text-gray-400">
                  ${invoice?.avg_car_price_per_day + " x "}
                  {invoice?.trip_days + "-Days  "}
                </span>
              </span>
            </div>
          </div>

          {invoice?.location_total !== "0.00" && (
            <div className="mt-4">
              <div className="pb-4">
                <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                  Location Total
                </span>
              </div>
              {invoice?.breakdown?.locations?.map((location: any) => (
                <>
                  <div className="flex justify-between ">
                    <span>{location?.name}</span>
                    <div className="flex flex-col items-end">
                      <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                        ${location?.total}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}

          {invoice?.insurance_total !== "0.00" && (
            <div className="mt-4">
              <div className="pb-4">
                <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                  Insurance Total
                </span>
              </div>
              {invoice?.breakdown?.insurance?.map((insurance: any) => (
                <>
                  <div className="flex justify-between ">
                    <span>{insurance?.name}</span>
                    <div className="flex flex-col items-end">
                      <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                        ${insurance?.total}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        {insurance?.rate}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}

          {invoice?.extras_total !== "0.00" && (
            <div className="mt-4">
              <div className="pb-4">
                <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                  Trip Extras
                </span>
              </div>
              {invoice?.breakdown?.extras?.map((extra: any) => (
                <>
                  <div className="flex justify-between ">
                    <span>{extra?.name}</span>
                    <div className="flex flex-col items-end">
                      <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                        ${extra?.total}
                      </span>
                      <span className="text-sm text-gray-500 dark:text-gray-400">
                        ${extra?.rate}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}

          {invoice?.booking_fee !== "0.00" &&
            invoice?.booking_fee !== undefined && (
              <div className="mt-4">
                <div className="pb-4">
                  <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                    Booking Fee
                  </span>
                </div>
                {invoice?.breakdown?.booking_fee?.map((bookingFee: any) => (
                  <>
                    <div className="flex justify-between ">
                      <span>
                        {" "}
                        {bookingFee?.description
                          ? bookingFee?.description
                          : bookingFee?.name}
                      </span>
                      <div className="flex flex-col items-end">
                        <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                          ${bookingFee?.total}
                        </span>
                        <span>
                          {bookingFee?.rate.includes("%")
                            ? bookingFee?.rate
                            : `$${bookingFee?.rate}`}
                        </span>
                      </div>
                    </div>
                  </>
                ))}
              </div>
            )}

          {invoice?.tax !== "0.00" && (
            <div className="mt-4">
              <div className="pb-4">
                <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                  Tax & Fee
                </span>
              </div>
              {invoice?.breakdown?.taxes?.map((taxes: any) => (
                <>
                  <div className="flex justify-between ">
                    <span>
                      {" "}
                      {taxes?.description ? taxes?.description : taxes?.name}
                    </span>
                    <div className="flex flex-col items-end">
                      <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                        ${taxes?.total}
                      </span>
                      <span>
                        {taxes?.rate.includes("%")
                          ? taxes?.rate
                          : `$${taxes?.rate}`}
                      </span>
                    </div>
                  </div>
                </>
              ))}
            </div>
          )}

          {invoice?.discount !== "0.00" && (
            <div className="mt-4">
              <div className="flex justify-between ">
                <span className="text-lg font-normal text-gray-700 dark:text-gray-300">
                  Discount
                </span>
                <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.discount}
                </span>
              </div>
            </div>
          )}

          <div className="mt-8 border-t border-neutral-700 dark:border-gray-700 pt-4">
            <div className="flex justify-between ">
              <div>
                <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                  Total Rental Price
                </span>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  Overall price rental
                </p>
              </div>
              <div>
                <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.total_price_without_security}
                </span>
              </div>
            </div>
          </div>

          {invoice.security_deposit_total !== "0.00" && (
            <div className="mt-4">
              <div className="flex justify-between ">
                <span className="text-lg font-semibold text-gray-700 dark:text-gray-300">
                  Total Deposit
                </span>
                <span className="text-xl font-medium text-gray-900 dark:text-gray-100">
                  ${invoice.security_deposit_total}
                </span>
              </div>
            </div>
          )}
        </div>

        <ButtonPrimary
          className="block rounded-lg"
          onClick={() => {
            handleBooking();
          }}
        >
          {loading ? (
            <div className="flex justify-center items-center col-span-full">
              <div className="spinner-border animate-spin inline-block w-6 h-6 border-4 rounded-full border-t-transparent border-gray-300"></div>
            </div>
          ) : (
            " Reserve"
          )}
        </ButtonPrimary>
      </div>
    );
  };

  return (
    <>
      <div className={` nc-ListingCarDetailPage `}>
        <header className="rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-1 md:grid-cols-4  gap-1 sm:gap-2 min-h-96 ">
            <div
              className="col-span-2 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={handleOpenModalImageGallery}
            >
              <img
                src={
                  (selectedCar?.fleet_photos?.length ?? 0) > 0
                    ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[0].photo_url}`
                    : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                }
                alt="0"
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            <div
              className="col-span-1 row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={handleOpenModalImageGallery}
            >
              <img
                className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                src={
                  (selectedCar?.fleet_photos?.length ?? 0) > 1
                    ? `https://fleet-management-images-upload-be.s3.amazonaws.com/${selectedCar?.fleet_photos[1].photo_url}`
                    : "https://tenant.fleetwire.io/images/vehicle-placeholder.jpg"
                }
                alt="1"
                sizes="400px"
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>

            {/*  */}
            {selectedCar?.fleet_photos
              .filter((_, i) => i >= 2 && i < 4)
              .map((item, index) => (
                <div
                  key={index}
                  className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                    index >= 2 ? "block" : ""
                  }`}
                >
                  <div className="aspect-w-4 aspect-h-3">
                    <img
                      className="absolute inset-0 object-cover w-full h-full rounded-md sm:rounded-xl "
                      src={
                        `https://fleet-management-images-upload-be.s3.amazonaws.com/${item.photo_url}` ||
                        ""
                      }
                      alt="photos"
                      sizes="400px"
                    />
                  </div>

                  {/* OVERLAY */}
                  <div
                    className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                    onClick={handleOpenModalImageGallery}
                  />
                </div>
              ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={handleOpenModalImageGallery}
            >
              <Squares2X2Icon className="h-5 w-5" />

              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>

        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
          <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:pr-10 lg:space-y-10">
            {renderUserSection()}

            {renderBonzahSection()}
            {renderExtrasSection()}
          </div>

          <div className="block flex-grow mt-0 lg:mt-0">
            <div className="block sticky top-28">{renderSidebarPrice()}</div>
          </div>
        </main>
      </div>
    </>
  );
};

export default function ListingCarDetailPage() {
  return (
    <DetailPagetLayout>
      <ListingCarDetail />
    </DetailPagetLayout>
  );
}
