import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { FooterLink } from "data/types";
import React from "react";
import { globalJson } from "global/global_json";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: FooterLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Address",
    menus: [
      {
        label: globalJson.address,
      },
    ],
  },
  {
    id: "1",
    title: "Contact Us",
    menus: [{ label: globalJson.phone }, { label: globalJson.email }],
  },
  {
    id: "2",
    title: "Explore",
    menus: [
      { href: "/vehicles", label: "Our Fleet" },
      { href: "/about", label: "About Us" },
      // { href: "/coming-soon", label: "Start Earning" },
    ],
  },
];

const locations = [
  {
    id: "3",
    title: "Our Location",
    menus: [
      {
        href: "/locations/deerfield-beach-car-rental",
        label: "Deerfield Beach Car Rental",
      },
      {
        href: "/locations/boca-raton-car-rental",
        label: "Boca Raton Car Rental",
      },
      {
        href: "/locations/west-palm-beach-car-rental",
        label: "West Palm Beach Car Rental",
      },
      {
        href: "/locations/pompano-beach-car-rental",
        label: "Pompano Beach Car Rental",
      },
      {
        href: "/locations/coconut-creek-car-rental",
        label: "Coconut Creek Car Rental",
      },
      {
        href: "/locations/delray-beach-car-rental",
        label: "Delray Beach Car Rental",
      },
      {
        href: "/locations/coral-springs-car-rental",
        label: "Coral Springs Car Rental",
      },
      {
        href: "/locations/fort-lauderdale-car-rental",
        label: "Fort Lauderdale Car Rental",
      },
      {
        href: "/locations/boynton-beach-car-rental",
        label: "Boynton Beach Car Rental",
      },
      { href: "/locations/davie-car-rental", label: "Davie Car Rental" },
      { href: "/locations/sunrise-car-rental", label: "Sunrise Car Rental" },
      {
        href: "/locations/hollywood-fl-car-rental",
        label: "Hollywood FL Car Rental",
      },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm lg:ml-16 sm:ml-0">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              {item.href ? (
                <a
                  className="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                  href={item.href}
                >
                  {item.label}
                </a>
              ) : (
                <span className="text-neutral-600 dark:text-neutral-300">
                  {item.label}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderLocationMenuItem = (location: any, index: number) => {
    return (
      <div key={index} className="text-sm ">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {location.title}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5">
          {location.menus.map((item: any, index: any) => (
            <div key={index} className="text-neutral-600 dark:text-neutral-300">
              <a
                className="hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-5 lg:gap-x-10 ">
        <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
          <div className="col-span-2 md:col-span-1">
            <Logo />
          </div>
          <div className="hidden lg:flex col-span-2 items-center md:col-span-3">
            <SocialsList1 className="flex items-center space-x-3 lg:space-x-0 lg:flex-col lg:space-y-2.5 lg:items-start" />
          </div>
        </div>
        {widgetMenus.map(renderWidgetMenuItem)}
        <SocialsList1 className="lg:hidden flex items-start space-x-3 lg:flex-col lg:space-y-2.5 lg:items-start" />
      </div>
        <div className="border-t border-neutral-200 dark:border-neutral-700 my-12"></div>
<div className="container">

        {locations.map(renderLocationMenuItem)}
</div>
    </div>
  );
};

export default Footer;
